import React from "react";
import styles from './Divider.module.scss';

interface IDivider {
    icon: React.ReactNode;
}

const Divider:React.FC<IDivider> = ({ icon }) => {
    return (
        <div className={styles.Divider}>
            <div className={styles.DividerIcon}>
                { icon }
            </div>
        </div>
    )
}

export default Divider;