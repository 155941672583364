import React from 'react';
import Logo from '../components/Logo/Logo';
import './splash.css';

const SplashScreen = () => {
    return (
        <div id="splash-container">
            <Logo style={{ height: '90%', width: '90%' }} />
        </div>
    )
}

export default SplashScreen;