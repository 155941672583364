import React from 'react';
import styles from './Column.module.scss';

interface IColumn {
    style?: any;
}

const Column:React.FC<IColumn> = ({ style, children }) => {
    return (
        <div style={style} className={styles.Column}>{children}</div>
    )
}

export default Column;