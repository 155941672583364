import React from 'react';
import Logo from '../Logo/Logo';

import styles from './Header.module.scss';

const Header:React.FC = () => {
    return (
        <header className={styles.Header}>
            <div>
                <Logo />
            </div>
        </header>
    )
}

export default Header;