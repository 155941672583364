import React, { useEffect, useState } from 'react';
import Column from './components/Column/Column';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import FooterIcon from './components/Icons/FooterIcon';
import RandDIcon from './components/Icons/RandDIcon';
import TargetIcon from './components/Icons/Target';
import Row from './components/Row/Row';
import Section from './components/Section/Section';
import FinishLine from './illustrations/FinishLine';
import Plant from './illustrations/Plant';
import Tech from './illustrations/Tech';
import SplashScreen from './pages/SplashScreen';

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    let timer:any = setTimeout(() => {
      setShowSplash(false);
    }, 4500);

    return () => {
      clearImmediate(timer);
    }
  }, []);

  return (
      !showSplash ? (
      <>
        <Header />
        <Hero />
        <Section icon={<RandDIcon />}>
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15% 32px 0 32px' }}>
            <Plant />
            <Column style={{ margin: 32, maxWidth: 460 }}>
              <h2>What we do...</h2>
              <p>
                We grow Hemp! Well we also grow other things, but thats the short of it. 

                We then harvest it, remove leaves and then process the fibre for textiles or grain for food suplements. And we choose to do this for sustainability and low envriomental impact. While textiles like cotton take 700gallons of water to grow our hemp basically grows itself, while absorbing CO2, this allows us to run at net Zero emmissions.

                But we still want to go further...
              </p>
            </Column>
          </Row>
          <div style={{ height: '100%', width: '100%', background: '#3F3D56' }}></div>
        </Section>
        <Section icon={<TargetIcon />}>
          <Row style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '0 32px' }}>
            <Column style={{ margin: 32, maxWidth: 460 }}>
              <h2>Research &amp; Development</h2>
              <p>
                We love research and development, and we love tech, so no wonder we do this. 

                We incorporate monitoring systems and AI into our growing to develop ways to produce the best crops for fibre and foods!
              </p>
            </Column>
            <Tech />
          </Row>
        </Section>
        <Section icon={<FooterIcon />}>
          <Row style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '0 32px' }}>
            <FinishLine />
            <Column style={{ margin: 32, maxWidth: 460 }}>
              <h2>Our Goal</h2>
              <p>
                Our dream is to create a world in which sustainability is key.

                This is why we are not only commited to improving our own methods, but which to enable others to create a sustainable living through helping those in poverty. 

                Collaboration and community support are important, and just like growing crops if the soil is unhealthy or dryed out then nothing can grow, this is the same for communities, so we, together shall create a fertile soil.

                Thus we are on a mission to grow our company, to provide work and education and food to those who need it.
              </p>
            </Column>
          </Row>
        </Section>
        <Footer />
      </>
      ) : (
        <>
          <SplashScreen />
        </>
      )
  );
}

export default App;
