import React from 'react';

const styles = {
    container: {
        width: '100%',
        height: '100%',
        maxHeight: 360,
        maxWidth: 460,
        overflow: 'hidden'
    }
};

const FinishLine = () => {
    return (
        <div style={styles.container}>
            <svg width="100%" height="100%" viewBox="0 0 473 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" d="M272.781 48.7001C244.542 47.6965 217.647 38.5818 191.983 28.6293C166.319 18.6768 140.864 7.60296 113.301 2.31911C95.5735 -1.07983 75.2976 -1.55978 61.0168 7.94329C47.2684 17.106 42.8266 32.8791 40.4399 47.5307C38.6423 58.5522 37.5864 70.1496 42.5081 80.4686C45.9289 87.633 51.9937 93.6542 56.1911 100.518C70.7948 124.393 60.4714 153.836 44.6504 177.149C37.233 188.087 28.6156 198.528 22.8867 210.161C17.1578 221.793 14.5094 235.14 19.5227 247.017C24.488 258.797 36.3254 267.629 49.1489 273.846C75.1929 286.473 105.871 290.09 135.807 292.137C202.053 296.67 268.649 294.707 335.07 292.743C359.652 292.015 384.34 291.277 408.52 287.477C421.95 285.365 435.812 282.014 445.564 273.929C457.938 263.662 461.006 246.275 452.711 233.404C438.801 211.81 400.353 206.448 390.618 183.27C385.269 170.517 390.762 156.306 398.542 144.477C415.227 119.1 443.191 96.835 444.661 67.824C445.673 47.9016 432.23 27.9486 411.444 18.5197C389.658 8.63705 359.443 9.88056 343.378 26.2382C326.806 43.0628 297.717 49.5422 272.781 48.7001Z" fill="#FF889E"/>
                <path d="M67.7188 304.38C85.3604 304.38 99.6618 301.629 99.6618 298.236C99.6618 294.844 85.3604 292.093 67.7188 292.093C50.0771 292.093 35.7757 294.844 35.7757 298.236C35.7757 301.629 50.0771 304.38 67.7188 304.38Z" fill="#FF889E"/>
                <path d="M67.1821 300.497C69.2352 300.497 70.8996 298.316 70.8996 295.627C70.8996 292.938 69.2352 290.758 67.1821 290.758C65.129 290.758 63.4647 292.938 63.4647 295.627C63.4647 298.316 65.129 300.497 67.1821 300.497Z" fill="#3F3D56"/>
                <path d="M67.1821 294.545C69.2352 294.545 70.8996 292.365 70.8996 289.676C70.8996 286.987 69.2352 284.807 67.1821 284.807C65.129 284.807 63.4647 286.987 63.4647 289.676C63.4647 292.365 65.129 294.545 67.1821 294.545Z" fill="#3F3D56"/>
                <path d="M67.1821 288.594C69.2352 288.594 70.8996 286.414 70.8996 283.724C70.8996 281.035 69.2352 278.855 67.1821 278.855C65.129 278.855 63.4647 281.035 63.4647 283.724C63.4647 286.414 65.129 288.594 67.1821 288.594Z" fill="#3F3D56"/>
                <path d="M67.1821 282.647C69.2352 282.647 70.8996 280.467 70.8996 277.778C70.8996 275.088 69.2352 272.908 67.1821 272.908C65.129 272.908 63.4647 275.088 63.4647 277.778C63.4647 280.467 65.129 282.647 67.1821 282.647Z" fill="#3F3D56"/>
                <path d="M67.1821 276.695C69.2352 276.695 70.8996 274.515 70.8996 271.826C70.8996 269.137 69.2352 266.957 67.1821 266.957C65.129 266.957 63.4647 269.137 63.4647 271.826C63.4647 274.515 65.129 276.695 67.1821 276.695Z" fill="#3F3D56"/>
                <path d="M81.1051 219.201C81.6146 218.555 82.0782 217.874 82.4926 217.164L72.719 215.558L83.2867 215.636C84.3013 213.431 84.8548 211.041 84.9133 208.614C84.9719 206.187 84.5342 203.773 83.627 201.521L69.451 208.878L82.5406 199.279C81.3144 197.164 79.6662 195.324 77.6984 193.874C75.7306 192.424 73.4854 191.394 71.1025 190.849C68.7196 190.303 66.25 190.254 63.8474 190.705C61.4447 191.155 59.1605 192.095 57.1367 193.466C55.1129 194.837 53.393 196.61 52.0839 198.674C50.7748 200.739 49.9046 203.051 49.5273 205.466C49.1501 207.881 49.274 210.348 49.8912 212.713C50.5084 215.078 51.6058 217.291 53.1151 219.214C52.3234 220.222 51.6418 221.312 51.0819 222.465L63.7701 229.053L50.2441 224.516C49.2926 227.323 49.0568 230.323 49.5582 233.244C50.0595 236.165 51.2821 238.915 53.1151 241.244C51.0479 243.872 49.7618 247.028 49.404 250.352C49.0462 253.677 49.631 257.034 51.0916 260.042C52.5522 263.049 54.8296 265.585 57.6635 267.359C60.4973 269.133 63.7733 270.074 67.1167 270.074C70.46 270.074 73.736 269.133 76.5698 267.359C79.4037 265.585 81.6811 263.049 83.1417 260.042C84.6023 257.034 85.1872 253.677 84.8293 250.352C84.4715 247.028 83.1854 243.872 81.1182 241.244C83.5885 238.104 84.9316 234.225 84.9316 230.229C84.9316 226.234 83.5885 222.354 81.1182 219.214L81.1051 219.201Z" fill="#FF889E"/>
                <path opacity="0.1" d="M49.306 230.21C49.3007 234.205 50.6444 238.086 53.1194 241.222C51.0522 243.85 49.7662 247.006 49.4083 250.331C49.0505 253.655 49.6353 257.013 51.0959 260.02C52.5565 263.028 54.8339 265.563 57.6678 267.337C60.5017 269.112 63.7776 270.052 67.121 270.052C70.4644 270.052 73.7403 269.112 76.5741 267.337C79.408 265.563 81.6854 263.028 83.146 260.02C84.6067 257.013 85.1915 253.655 84.8336 250.331C84.4758 247.006 83.1897 243.85 81.1225 241.222C83.4961 238.199 49.306 228.216 49.306 230.21Z" fill="black"/>
                <path d="M214.262 103.327C214.262 103.327 242.186 85.0019 331.632 104.636C421.078 124.271 472.127 108.563 472.127 108.563L472.564 127.325C472.564 127.325 373.082 138.233 334.686 127.325C296.29 116.417 242.068 108.816 222.927 125.706L214.262 103.327Z" fill="#F86D70"/>
                <path d="M203.764 131.514C203.764 131.514 174.966 149.839 85.5207 130.205C-3.92517 110.57 0.438039 126.278 0.438039 126.278V105.771C0.438039 105.771 44.5065 94.8628 82.9027 105.771C121.299 116.679 175.957 122.971 195.098 106.081L203.764 131.514Z" fill="#F86D70"/>
                <path d="M222.478 224.101C222.748 227.592 224.092 231.205 226.919 233.264C229.747 235.323 234.245 235.284 236.405 232.518C237.13 231.479 237.664 230.319 237.98 229.093C239.799 223.342 241.091 217.05 239.097 211.356C234.598 211.064 229.384 208.948 226.007 212.665C223.28 215.693 222.163 220.065 222.478 224.101Z" fill="#3F3D56"/>
                <path d="M243.36 188.921C242.762 193.995 241.745 199.048 241.85 204.157C241.889 206.129 242.099 208.084 242.103 210.065C242.111 215.208 240.756 220.262 238.176 224.712C237.905 225.235 237.541 225.705 237.103 226.1C236.069 226.937 234.511 226.868 233.351 226.226C232.217 225.521 231.295 224.521 230.685 223.333C227.434 217.766 227.342 210.999 226.055 204.685C225.069 199.855 223.341 195.173 222.717 190.286C222.312 187.14 222.373 183.934 221.727 180.827C221.531 179.889 220.981 178.48 220.619 177.096C220.287 175.862 220.104 174.649 220.444 173.815C221.138 172.109 223.411 172.772 224.89 172.877C230.099 173.263 235.33 173.25 240.537 172.838C241.052 172.799 241.636 172.777 241.998 173.143C242.156 173.328 242.275 173.544 242.348 173.776C242.762 174.91 243.079 176.078 243.294 177.267C244.014 181.067 243.814 185.051 243.36 188.921Z" fill="#FABDBD"/>
                <g opacity="0.1">
                <path opacity="0.1" d="M241.353 172.838C241.084 172.809 240.814 172.809 240.545 172.838C238.05 173.038 235.55 173.139 233.054 173.152C235.82 173.158 238.586 173.053 241.353 172.838Z" fill="black"/>
                <path opacity="0.1" d="M234.232 226.226C233.098 225.521 232.177 224.521 231.566 223.333C228.316 217.766 228.224 210.998 226.937 204.685C225.951 199.855 224.223 195.173 223.599 190.286C223.193 187.14 223.254 183.933 222.608 180.827C222.412 179.889 221.862 178.479 221.5 177.096C221.169 175.861 220.985 174.648 221.326 173.815C221.47 173.473 221.714 173.183 222.025 172.981C222.336 172.778 222.7 172.674 223.071 172.681C221.967 172.606 220.889 172.737 220.453 173.815C220.113 174.648 220.296 175.861 220.628 177.096C220.99 178.479 221.539 179.889 221.736 180.827C222.382 183.933 222.32 187.14 222.726 190.286C223.35 195.177 225.078 199.859 226.064 204.685C227.351 210.998 227.443 217.774 230.693 223.333C231.304 224.521 232.225 225.521 233.359 226.226C234.056 226.611 234.854 226.773 235.646 226.688C235.15 226.624 234.67 226.467 234.232 226.226Z" fill="black"/>
                </g>
                <path d="M259.857 215.68C257.239 220.916 257.676 228.333 257.676 228.333C258.112 245.786 251.131 282.437 251.131 282.437H245.022C245.022 282.437 244.15 241.859 245.022 238.369C245.895 234.878 244.586 222.661 243.277 205.645C242.304 192.991 244.952 181.525 246.375 176.494C246.863 174.749 247.204 173.793 247.204 173.793C247.204 173.793 257.239 158.958 262.911 165.94C264.22 167.563 264.914 170.839 265.154 174.976C265.931 188.624 261.864 211.662 259.857 215.68Z" fill="#FABDBD"/>
                <path opacity="0.1" d="M265.154 174.976C264.914 170.839 264.229 167.558 262.911 165.939C261.646 164.386 260.163 163.915 258.627 164.12C259.817 164.318 260.884 164.97 261.602 165.939C262.911 167.563 263.605 170.839 263.845 174.976C264.622 188.624 260.555 211.662 258.548 215.68C255.93 220.916 256.367 228.333 256.367 228.333C256.803 245.786 249.822 282.437 249.822 282.437H251.131C251.131 282.437 258.112 245.786 257.676 228.333C257.676 228.333 257.239 220.916 259.857 215.68C261.864 211.662 265.931 188.624 265.154 174.976Z" fill="black"/>
                <path d="M241.846 280.845C241.358 281.227 240.937 281.687 240.598 282.206C239.817 283.681 240.925 285.444 242.112 286.617C243.299 287.791 244.73 289.048 244.73 290.719C244.73 292.063 243.713 293.214 242.574 293.93C241.964 294.314 241.196 294.803 241.322 295.527C241.367 295.71 241.445 295.883 241.553 296.037C242.531 297.534 244.263 298.411 246.017 298.743C247.771 299.074 249.582 298.93 251.366 298.786C251.634 298.796 251.895 298.706 252.099 298.533C252.229 298.359 252.298 298.148 252.296 297.931C252.383 297.006 252.326 296.029 252.732 295.195C253.299 294.057 254.604 293.476 255.35 292.455C256.397 291.059 256.253 289.117 255.856 287.42C254.983 283.725 253.042 279.51 250.127 276.948C246.75 274.008 244.102 278.72 241.846 280.845Z" fill="#3F3D56"/>
                <path d="M255.066 60.9171C257.501 57.2781 256.868 52.1906 254.381 48.5692C251.894 44.9477 247.898 42.6701 243.774 41.1997C238.216 39.2188 232.085 38.5425 226.374 40.0304C221.827 41.2128 217.743 43.6867 213.459 45.5978C205.878 48.98 197.636 50.62 189.339 50.3974C180.128 50.1399 170.036 47.7794 162.309 52.8146C148.163 62.0166 154.455 87.0614 141.073 97.3193C137.465 100.086 132.923 101.294 128.908 103.428C124.894 105.561 121.09 109.275 121.185 113.821C121.229 116.003 122.189 118.084 123.367 119.929C128.385 127.884 137.766 132.805 147.16 132.714C156.554 132.622 165.721 127.626 171.136 119.938C172.493 118.014 173.688 115.872 175.656 114.589C179.583 112.015 184.757 113.817 189.444 114.201C199.876 115.051 210.313 107.219 212.39 96.9441C213.262 92.5591 213.11 87.3189 216.639 84.5657C219.489 82.3448 223.498 82.8204 227.111 82.4408C233.717 81.7557 238.931 77.6412 242.518 72.2658C244.163 69.8006 244.355 66.8554 246.597 64.9138C249.015 62.7801 252.754 64.3553 255.066 60.9171Z" fill="#3F3D56"/>
                <g opacity="0.1">
                <path opacity="0.1" d="M180.613 51.2392C184.439 51.3442 188.265 51.0518 192.031 50.3666C190.993 50.3971 189.945 50.3971 188.903 50.3666C181.673 50.1659 173.902 48.6737 167.156 50.4713C171.607 50.5149 176.21 51.1171 180.613 51.2392Z" fill="black"/>
                <path opacity="0.1" d="M166.921 115.457C164.953 116.74 163.757 118.882 162.4 120.806C158.23 126.661 152.134 130.863 145.179 132.679C145.689 132.7 146.2 132.713 146.715 132.709C156.117 132.617 165.276 127.622 170.691 119.934C172.047 118.009 173.243 115.867 175.211 114.584L175.551 114.375C172.471 113.952 169.447 113.803 166.921 115.457Z" fill="black"/>
                <path opacity="0.1" d="M253.941 48.556C251.458 44.952 247.457 42.6569 243.334 41.1865C237.775 39.2056 231.645 38.5293 225.933 40.0172C225.802 40.0477 225.676 40.087 225.549 40.1219C228.791 40.3027 231.99 40.9553 235.044 42.0592C239.167 43.5296 243.168 45.8246 245.651 49.4286C248.133 53.0326 248.77 58.1551 246.336 61.7765C244.023 65.2278 240.284 63.6527 237.836 65.7776C235.593 67.7192 235.401 70.6644 233.756 73.1296C230.672 77.7415 226.378 81.4197 221.033 82.8159C222.87 82.5978 224.807 82.6239 226.64 82.432C233.246 81.7469 238.46 77.6324 242.047 72.2569C243.691 69.7917 243.883 66.8466 246.126 64.9049C248.574 62.7801 252.313 64.3552 254.626 60.9039C257.065 57.265 256.432 52.16 253.941 48.556Z" fill="black"/>
                <path opacity="0.1" d="M218.354 83.3043C214.763 83.6795 210.754 83.2083 207.883 85.4292C204.353 88.1824 204.523 93.4226 203.633 97.8076C202.101 105.474 195.879 111.796 188.493 114.148L189.012 114.196C199.444 115.047 209.881 107.215 211.958 96.9393C212.831 92.5543 212.678 87.3141 216.208 84.5609C217.018 83.9546 217.938 83.5102 218.917 83.252C218.721 83.2563 218.542 83.2869 218.354 83.3043Z" fill="black"/>
                </g>
                <path d="M323.778 45.2966C323.778 45.2966 313.307 66.24 309.816 74.0938C306.325 81.9476 274.474 93.7283 270.111 94.1646C268.94 94.325 267.85 94.8502 266.995 95.6655C264.53 97.8471 263.13 101.582 263.13 101.582C263.13 101.582 264.875 113.363 262.693 132.997C262.65 133.377 262.61 133.756 262.575 134.123C260.673 152.806 264.657 157.213 264.657 157.213C264.657 157.213 222.334 173.357 223.643 152.413C224.053 145.821 223.992 141.004 223.586 136.976C223.142 132.854 222.303 128.783 221.081 124.82C220.047 121.203 218.812 117.237 217.534 111.836C216.919 109.14 216.108 106.494 215.108 103.916C215.078 103.838 215.047 103.759 215.021 103.685C212.839 98.2704 210.658 96.5207 209.41 95.9535C208.231 95.441 207.109 94.8094 206.059 94.0686C202.027 91.1583 193.375 84.6353 185.669 76.9299C175.198 66.4582 164.726 41.5879 164.726 41.5879C164.726 41.5879 161.672 27.1893 168.653 15.4086C175.634 3.62794 172.58 38.5336 172.58 38.5336C172.58 38.5336 185.233 55.5502 188.287 63.4039C189.858 67.4443 192.354 69.5124 194.396 70.5858C197.77 72.3419 201 74.3611 204.056 76.6245C206.122 78.1856 208.292 79.6026 210.553 80.8655C215.431 83.4834 221.273 86.4373 226.143 87.214C226.903 87.3396 227.672 87.4052 228.442 87.4103C228.808 87.422 229.171 87.3493 229.504 87.198C229.837 87.0467 230.13 86.8207 230.362 86.5377C231.671 84.9975 231.64 81.6814 231.339 78.9544C231.178 77.5417 230.939 76.139 230.624 74.7526C230.624 74.7526 244.804 65.8081 244.368 76.7161C244.3 77.9214 244.412 79.1301 244.699 80.3027C244.932 81.3789 245.445 82.3741 246.188 83.1867C246.931 83.9993 247.876 84.6001 248.927 84.9277C250.63 85.4271 252.432 85.4856 254.163 85.0978C254.703 85.0056 255.224 84.8274 255.708 84.5699C257.017 83.6972 270.106 79.7703 274.033 79.334C277.96 78.8977 297.595 69.2986 297.595 69.2986L318.974 39.6288C318.974 39.6288 318.538 22.176 327.264 15.1948C335.991 8.21368 323.778 45.2966 323.778 45.2966Z" fill="#FABDBD"/>
                <g opacity="0.1">
                <path opacity="0.1" d="M262.575 131.505C262.194 135.012 262.027 138.538 262.074 142.064C262.117 139.761 262.27 137.129 262.575 134.123C262.61 133.757 262.65 133.377 262.693 132.998C263.456 126.278 263.821 119.519 263.784 112.757C263.717 118.646 263.353 124.527 262.693 130.38C262.667 130.759 262.61 131.139 262.575 131.505Z" fill="black"/>
                <path opacity="0.1" d="M231.077 74.4736C230.785 74.6438 230.641 74.7485 230.641 74.7485C231.065 76.615 231.345 78.5113 231.479 80.4207C231.567 78.4296 231.432 76.4349 231.077 74.4736Z" fill="black"/>
                <path opacity="0.1" d="M172.785 36.1646C172.999 33.1103 173.317 27.6345 173.256 22.7651C173.182 29.275 172.602 35.9159 172.602 35.9159L172.785 36.1646Z" fill="black"/>
                <path opacity="0.1" d="M223.673 151.903C223.673 152.073 223.673 152.243 223.642 152.414C222.333 173.357 264.657 157.213 264.657 157.213C264.162 156.545 263.788 155.794 263.553 154.997C257.178 157.265 225.143 167.816 223.673 151.903Z" fill="black"/>
                <path opacity="0.1" d="M323.778 42.6787C323.778 42.6787 313.306 63.6222 309.816 71.4759C306.325 79.3297 274.474 91.1104 270.111 91.5467C268.94 91.7072 267.85 92.2324 266.995 93.0477C264.53 95.2293 263.13 98.9642 263.13 98.9642C263.13 98.9642 263.234 99.6754 263.361 101.028C263.849 99.9066 265.106 97.3323 266.995 95.6656C267.85 94.8503 268.94 94.3251 270.111 94.1646C274.474 93.7283 306.325 81.9477 309.816 74.0939C313.306 66.2401 323.778 45.2967 323.778 45.2967C323.778 45.2967 331.156 22.8916 330.345 16.1897C329.673 24.7765 323.778 42.6787 323.778 42.6787Z" fill="black"/>
                <path opacity="0.1" d="M223.586 134.359C223.142 130.236 222.303 126.165 221.081 122.203C220.047 118.586 218.812 114.62 217.534 109.218C216.919 106.523 216.108 103.876 215.108 101.299C215.077 101.22 215.047 101.142 215.021 101.067C212.839 95.6527 210.658 93.903 209.41 93.3358C208.231 92.8233 207.109 92.1917 206.059 91.4509C202.027 88.5407 193.375 82.0177 185.669 74.3122C175.198 63.8405 164.726 38.9702 164.726 38.9702C164.339 36.8707 164.123 34.7431 164.08 32.6086C163.974 35.6163 164.19 38.6267 164.726 41.5881C164.726 41.5881 175.198 66.4584 185.669 76.9302C193.375 84.6356 202.027 91.1586 206.059 94.0688C207.117 94.8112 208.249 95.4428 209.436 95.9537C210.679 96.521 212.857 98.2706 215.047 103.685C215.073 103.76 215.104 103.838 215.134 103.917C216.13 106.494 216.936 109.141 217.547 111.836C218.825 117.237 220.06 121.204 221.094 124.821C222.316 128.783 223.155 132.854 223.599 136.977C223.782 138.8 223.891 140.786 223.922 143.02C223.975 140.129 223.862 137.237 223.586 134.359Z" fill="black"/>
                </g>
                <path d="M266.799 115.405C266.133 116.506 265.55 117.656 265.054 118.843C264.395 120.676 264.391 122.67 264.268 124.611C264.094 127.336 263.68 130.04 263.029 132.692C262.924 133.18 262.778 133.659 262.593 134.123C262.463 134.436 262.291 134.729 262.082 134.996C261.85 135.28 261.576 135.529 261.271 135.733C260.803 136.045 260.308 136.316 259.792 136.54C253.718 139.377 247.095 141.881 240.467 140.904C237.665 140.414 234.954 139.502 232.426 138.198C230.026 137.033 227.312 135.65 224.825 136.619C224.443 136.813 224.032 136.941 223.608 136.999C223.526 137 223.444 136.993 223.363 136.977C222.543 136.82 222.543 134.053 222.355 133.237C221.64 130.105 221.531 127.565 221.103 124.843C220.876 123.337 220.524 121.853 220.052 120.405C218.306 115.126 217.778 109.497 217.778 103.965C217.778 103.69 215.815 103.965 215.16 103.939C214.995 103.939 214.903 103.912 214.942 103.856C214.98 103.801 215.024 103.752 215.073 103.707C215.302 103.522 215.568 103.389 215.854 103.319L216.369 103.166C219.066 102.403 222.041 102.084 224.057 100.186C226.151 98.2271 226.675 95.0986 226.583 92.2364C226.557 91.3157 226.108 89.0818 226.182 87.2274C226.942 87.353 227.711 87.4187 228.481 87.4237C228.847 87.4354 229.21 87.3627 229.543 87.2114C229.876 87.0601 230.17 86.8342 230.401 86.5511C231.129 87.9604 231.759 89.4183 232.286 90.9143C233.028 92.7381 233.595 94.6579 234.681 96.3072C236.427 98.995 239.647 100.762 242.849 100.492C246.052 100.221 249.032 97.7602 249.604 94.6012C250.105 91.8306 248.94 87.8426 248.967 84.9629C250.669 85.4623 252.471 85.5209 254.202 85.1331C254.276 85.3109 254.339 85.493 254.39 85.6785C255.372 89.0469 256.681 92.9127 259.975 94.1082C262.104 94.8761 264.622 94.3002 266.59 95.4172C266.741 95.5041 266.887 95.6003 267.026 95.7051C267.48 96.0429 267.872 96.4579 268.182 96.9312C268.991 98.2657 269.471 99.7731 269.583 101.329C270.087 106.194 269.117 111.098 266.799 115.405Z" fill="#FF889E"/>
                <path opacity="0.1" d="M244.699 80.2984C242.523 81.1373 240.179 81.4524 237.858 81.2182C235.538 80.984 233.304 80.2071 231.339 78.9501C231.178 77.5374 230.94 76.1347 230.624 74.7484C230.624 74.7484 244.804 65.8038 244.368 76.7118C244.3 77.9172 244.412 79.1259 244.699 80.2984Z" fill="black"/>
                <path d="M239.346 80.4206C247.539 80.4206 254.181 73.7787 254.181 65.5857C254.181 57.3926 247.539 50.7507 239.346 50.7507C231.153 50.7507 224.511 57.3926 224.511 65.5857C224.511 73.7787 231.153 80.4206 239.346 80.4206Z" fill="#FABDBD"/>
                <path d="M226.588 68.0423C226.151 65.3197 225.479 61.8902 227.757 60.3325C228.569 59.84 229.509 59.5972 230.458 59.6344C235.174 59.5864 239.682 62.8719 244.307 61.912C245.779 61.6036 247.13 60.8765 248.199 59.8182C249.267 58.76 250.007 57.4156 250.33 55.9467C250.652 54.4779 250.544 52.9471 250.017 51.5385C249.49 50.1298 248.568 48.9033 247.361 48.0065C245.541 46.6539 243.242 46.1652 241.017 45.7071C235.166 44.5028 229.057 43.3029 223.28 44.8344C217.503 46.3659 212.224 51.545 212.686 57.4877C212.905 60.3325 214.353 62.9156 215.867 65.3415C217.019 67.1828 221.505 75.8132 223.682 75.9441C226.753 76.123 226.889 69.8225 226.588 68.0423Z" fill="#3F3D56"/>
                <path d="M263.348 149.796C263.348 149.796 233.242 157.213 223.206 149.796C223.206 149.796 218.843 166.812 220.152 175.539C220.152 175.539 241.532 176.848 243.713 178.157C243.713 178.157 244.15 174.666 246.331 176.411C246.331 176.411 253.749 167.685 267.275 175.539C267.275 175.539 269.893 155.032 263.348 149.796Z" fill="#FF889E"/>
                <path opacity="0.1" d="M247.37 48.0064C247.059 47.7814 246.734 47.5774 246.397 47.3955C247.297 48.4191 247.913 49.661 248.183 50.9972C248.453 52.3335 248.367 53.7171 247.935 55.0099C247.503 56.3027 246.738 57.4594 245.719 58.3643C244.699 59.2693 243.46 59.8907 242.125 60.1666C237.5 61.1265 232.993 57.841 228.276 57.889C227.327 57.8518 226.388 58.0946 225.575 58.5871C223.298 60.1448 223.965 63.5743 224.402 66.2969C224.69 68.0771 224.572 74.3776 221.509 74.2074H221.457C222.299 75.2328 223.084 75.9265 223.691 75.9527C226.745 76.1229 226.871 69.8442 226.583 68.0422C226.147 65.3196 225.479 61.8901 227.757 60.3324C228.569 59.8399 229.509 59.5971 230.458 59.6343C235.174 59.5863 239.682 62.8718 244.307 61.9119C245.779 61.6035 247.13 60.8764 248.199 59.8181C249.267 58.7599 250.007 57.4155 250.33 55.9466C250.652 54.4778 250.544 52.947 250.017 51.5384C249.49 50.1297 248.568 48.9032 247.361 48.0064H247.37Z" fill="black"/>
            </svg>
        </div>
    )
}

export default FinishLine;