import React from "react";
import Column from "../Column/Column";
import CopyrightIcon from "../Icons/CopyrightIcon";
import Logo from "../Logo/Logo";
// import Newsletter from "../NewsLetter/NewsLetter";
import Row from "../Row/Row";

import Typography from "../Typography/Typography";

import styles from './Footer.module.scss';

const Footer:React.FC = () => {
    return (
        <footer className={styles.Footer}>
            <Row style={{ padding: '64px 0 0 32px' }}>
                <Logo color='#FF889E' style={{ height: 64, width: 268 }}/>
            </Row>
            <Row>
                <Column style={{ margin: '0 32px' }}>
                    <div className={styles.FooterListTitle}>
                        <Typography variant="h6">
                            Support
                        </Typography>
                    </div>
                    <ul className={styles.FooterList}>
                        <li className={styles.FooterListItem}>
                            <Typography variant="b1">
                                <a className={styles.FooterLink} href='/contact-us'>
                                    Contact Us
                                </a>
                            </Typography>
                        </li>
                        <li className={styles.FooterListItem}>
                            <Typography variant="b1">
                                <a className={styles.FooterLink} href='/FAQ'>
                                    FAQ
                                </a>
                            </Typography>
                        </li>
                    </ul>
                </Column>
                <Column style={{ margin: '0 32px' }}>
                    <div className={styles.FooterListTitle}>
                        <Typography variant="h6">
                            Our Work
                        </Typography>
                    </div>
                    <ul className={styles.FooterList}>
                        <li className={styles.FooterListItem}>
                            <Typography variant="b1">
                                <a className={styles.FooterLink} href='/careers'>
                                    Careers
                                </a>
                            </Typography>
                        </li>
                    </ul>
                </Column>
            </Row>
            {/* <Row style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 32px' }}>
                <Newsletter />
            </Row> */}
            <Row style={{ margin: 32 }}>
                <address style={{ color: '#FF889E'}}>
                    Konohana Limited<br />
                    Kemp House<br />
                    152-160 City Road<br />
                    London <br />
                    EC1V 2NX<br />
                </address>
            </Row>
            <Row style={{
                display: 'flex',
                position: 'absolute',
                right: 16,
                bottom: 16,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }}>
                <CopyrightIcon />
                <small style={{ marginLeft: 8, color: '#FF889E' }}>
                    Copyright 2021 Konohana Limited - All Rights Reserved
                </small>
            </Row>
        </footer>
    )
}

export default Footer;