import React from 'react';
import IconBase from './IconBase';

const FooterIcon = () => {
    return (
        <IconBase>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.33333 17.3334C6.06971 17.3334 6.66667 16.7365 6.66667 16.0001C6.66667 15.2637 6.06971 14.6667 5.33333 14.6667C4.59695 14.6667 4 15.2637 4 16.0001C4 16.7365 4.59695 17.3334 5.33333 17.3334Z" stroke="#FF889E" strokeWidth="4"/>
                <path d="M16 17.3334C16.7363 17.3334 17.3333 16.7365 17.3333 16.0001C17.3333 15.2637 16.7363 14.6667 16 14.6667C15.2636 14.6667 14.6666 15.2637 14.6666 16.0001C14.6666 16.7365 15.2636 17.3334 16 17.3334Z" stroke="#FF889E" strokeWidth="4"/>
                <path d="M26.6667 17.3334C27.4031 17.3334 28 16.7365 28 16.0001C28 15.2637 27.4031 14.6667 26.6667 14.6667C25.9303 14.6667 25.3334 15.2637 25.3334 16.0001C25.3334 16.7365 25.9303 17.3334 26.6667 17.3334Z" stroke="#FF889E" strokeWidth="4"/>
            </svg>
        </IconBase>
    )
}

export default FooterIcon;