import React from 'react';
import './plant.css';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '100%',
        height: '100%',
        minHeight: 320,
        // maxHeight: 360,
        maxWidth: 460,
        overflow: 'hidden'
    }
};

const Plant = () => {
    return (
        <div style={styles.container}>
            <svg width="100%" height="100%" viewBox="0 0 453 356" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="plant">
                    <g id="flowerRight">
                        <path id="rightFlower" d="M299.677 314.284C327.629 314.284 350.289 291.928 350.289 264.351C350.289 236.774 327.629 214.419 299.677 214.419C271.725 214.419 249.066 236.774 249.066 264.351C249.066 291.928 271.725 314.284 299.677 314.284Z" fill="#FFB2C1"/>
                        <path id="rightStem" d="M299.225 354.649L299.64 264.074" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10"/>
                        <path id="rightFlowerStigma" d="M299.64 276.885C306.81 276.885 312.623 271.15 312.623 264.076C312.623 257.002 306.81 251.268 299.64 251.268C292.469 251.268 286.657 257.002 286.657 264.076C286.657 271.15 292.469 276.885 299.64 276.885Z" fill="#3F3D56"/>
                        <path id="rightLeaf" d="M299.378 319.898C299.378 319.898 297.704 280.535 259.662 284.94Z" fill="#3F3D56"/>
                    </g>
                    <g id="flowerLeft">
                        <path id="leftFlower" d="M87.6633 214.033C115.615 214.033 138.275 191.677 138.275 164.1C138.275 136.523 115.615 114.168 87.6633 114.168C59.7113 114.168 37.0518 136.523 37.0518 164.1C37.0518 191.677 59.7113 214.033 87.6633 214.033Z" fill="#FFB2C1"/>
                        <path id="leftStem" d="M84.7946 348.439L87.7074 164.045" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10"/>
                        <path id="leftFlowerStigma" d="M87.7086 176.854C94.8788 176.854 100.691 171.119 100.691 164.045C100.691 156.971 94.8788 151.236 87.7086 151.236C80.5383 151.236 74.7257 156.971 74.7257 164.045C74.7257 171.119 80.5383 176.854 87.7086 176.854Z" fill="#3F3D56"/>
                        <path id="leftLeaf" d="M86.8844 216.201C86.8844 216.201 85.6516 176.822 47.5627 180.812Z" fill="#3F3D56"/>
                        <path id="leftBranch" d="M84.8534 344.918C84.8534 344.918 94.3939 269.375 46.2355 264.975" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10"/>
                        <path id="leftPod" d="M46.2317 277.783C53.402 277.783 59.2146 272.049 59.2146 264.975C59.2146 257.901 53.402 252.166 46.2317 252.166C39.0615 252.166 33.2489 257.901 33.2489 264.975C33.2489 272.049 39.0615 277.783 46.2317 277.783Z" fill="#3F3D56"/>
                    </g>
                    <g id="flowerCenter">
                        <path id="centerFlower" d="M196.505 103.182C224.457 103.182 247.117 80.8269 247.117 53.2499C247.117 25.6729 224.457 3.31738 196.505 3.31738C168.553 3.31738 145.894 25.6729 145.894 53.2499C145.894 80.8269 168.553 103.182 196.505 103.182Z" fill="#FFB2C1"/>
                        <path id="centerStem" d="M196.534 355.548V53.4301" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10"/>
                        <path id="centerFlowerStigma" d="M196.531 66.097C203.701 66.097 209.514 60.3624 209.514 53.2883C209.514 46.2143 203.701 40.4797 196.531 40.4797C189.361 40.4797 183.548 46.2143 183.548 53.2883C183.548 60.3624 189.361 66.097 196.531 66.097Z" fill="#3F3D56"/>
                        <path id="centerLeaf" d="M196.537 118.714C196.537 118.714 194.682 79.3587 156.661 83.9332Z" fill="#3F3D56"/>
                        <path id="centerBranch" d="M196.537 352.029C196.537 352.029 204.883 275.532 156.661 271.872" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10"/>
                        <path id="centerPod" d="M156.657 284.68C163.827 284.68 169.64 278.946 169.64 271.872C169.64 264.798 163.827 259.063 156.657 259.063C149.487 259.063 143.674 264.798 143.674 271.872C143.674 278.946 149.487 284.68 156.657 284.68Z" fill="#3F3D56"/>
                        <path id="centerBranch2" d="M197.333 227.799C197.333 227.799 188.987 155.383 237.209 151.724" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10"/>
                    </g>
                    <g id="dots">
                        <path id="dot3" d="M304.778 122.788C301.178 122.788 297.66 121.735 294.667 119.763C291.675 117.79 289.342 114.986 287.965 111.705C286.587 108.425 286.227 104.815 286.929 101.332C287.631 97.8492 289.365 94.6502 291.91 92.1393C294.455 89.6284 297.697 87.9185 301.227 87.2257C304.757 86.533 308.416 86.8885 311.742 88.2474C315.067 89.6063 317.909 91.9075 319.909 94.8599C321.908 97.8124 322.976 101.284 322.976 104.835C322.97 109.595 321.051 114.158 317.639 117.524C314.228 120.89 309.602 122.783 304.778 122.788V122.788ZM304.778 88.5129C301.506 88.5129 298.307 89.4702 295.586 91.2636C292.866 93.057 290.745 95.6061 289.493 98.5885C288.241 101.571 287.913 104.853 288.552 108.019C289.19 111.185 290.766 114.093 293.079 116.376C295.393 118.658 298.341 120.213 301.55 120.843C304.759 121.472 308.086 121.149 311.109 119.914C314.131 118.678 316.715 116.586 318.533 113.902C320.351 111.218 321.321 108.063 321.321 104.835C321.316 100.507 319.572 96.3587 316.47 93.2989C313.369 90.239 309.164 88.5179 304.778 88.5129Z" fill="#3F3D56"/>
                        <path id="dot4" d="M18.5708 36.2853C14.9716 36.2853 11.4532 35.2323 8.46059 33.2595C5.46795 31.2868 3.13547 28.4828 1.75811 25.2021C0.380746 21.9215 0.0203693 18.3116 0.722541 14.8289C1.42471 11.3462 3.15791 8.14713 5.70294 5.63624C8.24796 3.12536 11.4905 1.41542 15.0206 0.72267C18.5506 0.0299185 22.2096 0.385467 25.5349 1.74435C28.8601 3.10323 31.7023 5.40441 33.7019 8.3569C35.7015 11.3094 36.7688 14.7806 36.7688 18.3315C36.7633 23.0915 34.8443 27.655 31.4327 31.0208C28.0211 34.3867 23.3956 36.2799 18.5708 36.2853V36.2853ZM18.5708 2.00986C15.2988 2.00986 12.1003 2.9671 9.3797 4.76055C6.65912 6.554 4.53868 9.10309 3.28654 12.0855C2.03439 15.0679 1.70678 18.3496 2.34512 21.5157C2.98346 24.6818 4.55908 27.59 6.87275 29.8726C9.18641 32.1553 12.1342 33.7098 15.3433 34.3395C18.5525 34.9693 21.8788 34.6461 24.9018 33.4107C27.9247 32.1754 30.5085 30.0834 32.3263 27.3993C34.1441 24.7152 35.1144 21.5596 35.1144 18.3315C35.1094 14.0043 33.3648 9.85565 30.2634 6.79582C27.1619 3.73599 22.9569 2.01481 18.5708 2.00986V2.00986Z" fill="#3F3D56"/>
                        <path id="dot1" d="M434.643 302.326C431.044 302.326 427.526 301.273 424.533 299.3C421.541 297.327 419.208 294.523 417.831 291.243C416.453 287.962 416.093 284.352 416.795 280.87C417.497 277.387 419.231 274.188 421.776 271.677C424.321 269.166 427.563 267.456 431.093 266.763C434.623 266.071 438.282 266.426 441.608 267.785C444.933 269.144 447.775 271.445 449.774 274.398C451.774 277.35 452.841 280.821 452.841 284.372C452.836 289.132 450.917 293.696 447.505 297.062C444.094 300.427 439.468 302.321 434.643 302.326ZM434.643 268.051C431.371 268.051 428.173 269.008 425.452 270.801C422.732 272.595 420.611 275.144 419.359 278.126C418.107 281.109 417.779 284.39 418.418 287.556C419.056 290.722 420.632 293.631 422.945 295.913C425.259 298.196 428.207 299.75 431.416 300.38C434.625 301.01 437.952 300.687 440.974 299.451C443.997 298.216 446.581 296.124 448.399 293.44C450.217 290.756 451.187 287.6 451.187 284.372C451.182 280.045 449.437 275.896 446.336 272.837C443.235 269.777 439.03 268.056 434.643 268.051V268.051Z" fill="#3F3D56"/>
                        <path id="dot2" d="M357.716 161.962C363.655 161.962 368.469 157.212 368.469 151.353C368.469 145.493 363.655 140.744 357.716 140.744C351.777 140.744 346.963 145.493 346.963 151.353C346.963 157.212 351.777 161.962 357.716 161.962Z" fill="#3F3D56"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Plant;