import React, { CSSProperties } from 'react';
import Image from '../../assets/hero.png';
import Typography from '../Typography/Typography';
import styles from './Hero.module.scss';

const internalStyles:CSSProperties = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: `url(${Image})no-repeat center center/cover`
}

const Hero:React.FC = () => {
    return (
        <div className={styles.Hero}>
            <div style={internalStyles} className={styles.Hero}></div>
            <div className={styles.Overlay}></div>
            <div className={styles.Text}>
                <Typography variant="h2">
                    Growing for the Future
                </Typography>
            </div>
        </div>
    )
}

export default Hero;