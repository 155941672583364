import React from 'react';

import styles from './Row.module.scss';

interface IRow {
    style?: any;
}

const Row:React.FC<IRow> = ({ style, children }) => {
    return <div style={style} className={styles.Row}>{children}</div>
}

export default Row;