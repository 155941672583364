import React from 'react';

const styles = {
    iconBase: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 56,
        width: 56,
        border: '4px solid #fff',
        borderRadius: '50%',
        background: '#3F3D56'
    }
}

const IconBase:React.FC = ({ children }) => {
    return (
        <div style={styles.iconBase}>
            {children}
        </div>
    )
}

export default IconBase;