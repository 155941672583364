import React from 'react';
import styles from './Typography.module.scss';

interface ITypography {
    variant?: string;
}

const Typography:React.FC<ITypography> = ({ variant = 'b1', children }) => {
    let Component;

    switch(variant) {
        case 'h1':
            Component = ({ className }:any) => <h1 className={className}>{children}</h1>;
            break;
        case 'h2':
            Component = ({ className }:any) => <h2 className={className}>{children}</h2>;
            break;
        case 'h3':
            Component = ({ className }:any) => <h3 className={className}>{children}</h3>;
            break;
        case 'h4':
            Component = ({ className }:any) => <h4 className={className}>{children}</h4>;
            break;
        case 'h5':
            Component = ({ className }:any) => <h5 className={className}>{children}</h5>;
            break;
        case 'h6':
            Component = ({ className }:any) => <h6 className={className}>{children}</h6>;
            break;
        case 's1':
            Component = ({ className }:any) => <p className={className}>{children}</p>;
            break;
        case 's2':
            Component = ({ className }:any) => <p className={className}>{children}</p>;
            break;
        case 'b2':
            Component = ({ className }:any) => <p className={className}>{children}</p>;
            break;
        case 'button':
            Component = ({ className }:any) => <span className={className}>{children}</span>;
            break;
        case 'caption':
            Component = ({ className }:any) => <p className={className}>{children}</p>;
            break;
        case 'overline':
            Component = ({ className }:any) => <span className={className}>{children}</span>;
            break;
        default:
            Component = ({ className }:any) => <p className={className}>{children}</p>;
    }

    return <Component className={styles[variant]} />
}

export default Typography;