import React from 'react';
import Divider from '../Divider/Divider';
import styles from './Section.module.scss';

interface ISection {
    icon: React.ReactNode;
}

const Section:React.FC<ISection> = ({ icon, children }) => {
    return (
        <section className={styles.Section}>
            {children}
            <Divider icon={icon} />
        </section>
    )
}

export default Section;